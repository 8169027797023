<template>
    <div class="content-wrapper">
        <!-- <div v-if="loader" class="page_loader"></div> -->
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <h1 class="m-0 text-dark">All Trash Order</h1>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchOrder">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="contact">Contact</label>
                                                <input type="text" id="contact" v-model="search.contact" placeholder="Name, Mobile, Address" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">                                            
                                                <label for="sku">Order ID</label>
                                                <input type="text" id="sku" v-model="search.sku" placeholder="Order ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">                                            
                                                <label for="ref_id">Ref. ID</label>
                                                <input type="text" id="ref_id" v-model="search.ref_id" placeholder="Reference ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="rider_id">Rider</label>
                                                <v-select name="rider_id"
                                                    v-model="search.rider_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="area_id">Area</label>
                                                <v-select name="area_id"
                                                    v-model="search.area_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.areaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="merchant_id">Merchant</label>
                                                <v-select name="merchant_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="date_parameter_id">Date Parameter</label>
                                                <v-select name="date_parameter_id"
                                                    v-model="search.date_parameter_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= customState.dateParameterList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="date_type_id">Date</label>
                                                <v-select name="date_type_id"
                                                    v-model="search.date_type_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= customState.dateTypeList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control">                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control">                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="source_hub_id">Source Hub</label>
                                                <v-select name="source_hub_id"
                                                    v-model="search.source_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2" v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="destination_hub_id">Destination Hub</label>
                                                <v-select name="destination_hub_id"
                                                    v-model="search.destination_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                    </div>   
                                </form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group" style="margin-bottom:0px;">
                                            <label>
                                                <input type="checkbox" @click="selectAll" v-model="allSelected">
                                                <p style="display: inline-block; margin-left: 4px; font-size: 15px;">Select All | Selected ({{ totalSelected }} / {{ totalOrder }}) </p>
                                            </label>
                                            <button class="btn btn-info btn-sm ml-2 mr-2" type="submit" @click="showActionPanelModal"><i class="fa fa-check"></i> Take Action</button>
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchOrder"><i class="fa fa-search"></i> Filter</button>                                        
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-header">
                                <!-- <Summary :search="search"></Summary> -->
                                <div class="row">
                                    <div class="col-md-12 summary">
                                        <p class="mr-10">Order : <b>{{ summary ? summary.total_order : 0 }}</b>(Completed: <b>{{ summary ? summary.completed : 0 }}</b> Incompleted: <b>{{ summary ? summary.incompleted : 0 }}</b>)</p>
                                        <p class="mr-10">Amount : <b>{{ summary ? summary.total_amount : 0 }}</b></p>
                                        <p class="mr-10">Collected : <b>{{ summary ? summary.total_collected : 0 }}</b></p>
                                        <p class="mr-10">Service Charge : <b>{{ summary ? summary.total_service_charge : 0 }}</b></p>
                                        <p class="mr-10">COD Charge : <b>{{ summary ? summary.total_cod : 0 }}</b></p>
                                        <p class="mr-10">Area Charge : <b>{{ summary ? summary.total_area_charge : 0 }}</b></p>                                        
                                        <p class="mr-10">Weight Charge : <b>{{ summary ? summary.total_weight_charge : 0 }}</b></p>
                                        <p class="mr-10">Return Charge : <b>{{ summary ? summary.total_return_charge : 0 }}</b></p>
                                        <p class="ml-20">
                                            <slot v-if="loading">
                                                <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                            </slot>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">                                
                                <div class="table-responsive">
                                    <div style="width:100%; overflow-x:auto;">
                                        <table id="orderTable" class="table table-bordered table-sm" style="width:2000px">
                                            <thead>
                                                <tr class="text-center">
                                                    <th style="width:13%">Order ID</th>
                                                    <th style="width:8%">Merchant</th>
                                                    <th style="width:8%">Customer</th>
                                                    <th style="width:10%">Address</th>
                                                    <th style="width:8%">Status</th>  
                                                    <th style="width:6%">Destination Hub</th>
                                                    <th style="width:6%">Date</th>
                                                    <th style="width:6%">Instruction</th>
                                                    <th style="width:4%">Price</th>
                                                    <th style="width:5%">Collected</th>
                                                    <th style="width:6%">Source Hub</th>
                                                    <th style="width:8%">Rider</th>     
                                                    <th style="width:6%">Payment</th>     
                                                    <th style="width:6%">Payment At</th>     
                                                    <th style="width:6%">Delivery Charge</th>     
                                                    <th style="width:4%">COD</th>     
                                                    <th style="width:4%">Return Charge</th>     
                                                    <th style="width:6%">Last Update</th>     
                                                    <th style="width:4%">Age (days)</th>     
                                                    <th style="width:6%">Number of Attempt</th>     
                                                    <th style="width:5%">Action</th>                                       
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order,key) in orders" :key="key">
                                                    <td style="width:13%" class="order-id">
                                                        <label>
                                                            <input v-if="isSelected" v-model="ordersData" @click="singleSelect(order)" :value="order" type="checkbox" class="order-checkbox" />
                                                            <p>{{ order.id }}</p>
                                                        </label>
                                                    </td>
                                                    <td style="width:8%" @click="editModal(key, order)">{{ order.merchant.business }}</td>
                                                    <td style="width:8%">{{ order.name }}, {{ order.mobile }}{{ order.mobile_2 ? ', '+ order.mobile_2 : '' }}</td>
                                                    <td style="width:10%">{{ order.address }}</td>
                                                    <td style="width:8%" class="text-center"><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>                                    
                                                    <td style="width:6%">{{ order.destination ? order.destination.nam : '' }}</td>
                                                    <td style="width:6%">{{ order.date }}</td>
                                                    <td style="width:6%">{{ order.instruction }}</td>
                                                    <td style="width:4%" class="text-center">{{ order.price }}</td>
                                                    <td style="width:5%" class="text-center">{{ order.collected }}</td>
                                                    <td style="width:6%" class="text-center">{{ order.source ? order.source.name : '' }}</td>
                                                    <td style="width:8%">{{ order.rider ? (order.rider.name +', '+ order.rider.mobile) : '' }}</td>
                                                    <td style="width:6%" class="text-center"><span :class="'payment-status-'+order.payment_status">{{ order.payment_status != 0 ? getPaymentStatus(order.payment_status) : '' }}</span></td>                                         
                                                    <td style="width:6%" class="text-center">{{ order.payment_status == 3 ?(order.updated_at | dateformat + 'at'+ order.updated_at | timeformat) : '' }}</td>                                      
                                                    <td style="width:6%" class="text-center">{{ order.service_charge }}</td>                                      
                                                    <td style="width:4%" class="text-center">{{ order.collected != null ? order.cod : '' }}</td>                                      
                                                    <td style="width:4%" class="text-center">{{ order.return_charge }}</td>                                      
                                                    <td style="width:6%">{{ order.updated_at | dateformat }} at {{ order.updated_at | timeformat }}</td>                                      
                                                    <td style="width:4%" class="text-center">{{ order.age }}</td>                                      
                                                    <td style="width:6%" class="text-center">{{ order.attempt }}</td>     
                                                    <td style="width:5%" class="text-center">
                                                        <!-- <button class="btn btn-danger btn-sm mr-1" @click="deleteOrder(key, order.id)" title="Delete"><i class="fas fa-trash"></i></button> -->
                                                    </td>
                                                </tr>
                                                <infineLoading v-if="spinner" @distance="1" @infinite="loadData"></infineLoading>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div v-if="orderEditModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Details & Edit</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="heading1" style="padding:0px">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-controls="collapseOne">
                                            Edit
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordion">
                                    <div class="card-body">
                                        <b-overlay :show="loading">
                                            <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(updateForm)">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="merchant_id"
                                                                        v-model="form_edit.merchant_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.merchantList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Customer Name" required />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                                <div class="form-group">
                                                                    <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}" rules="min:11|max:11">
                                                                <div class="form-group">
                                                                    <label for="mobile_2">Alternatvie Mobile</label>
                                                                    <input type="text" id="mobile_2" v-model="form_edit.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="D" vid="coverage_area_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="coverage_area_id">Coverage Area <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="coverage_area_id"
                                                                        v-model="form_edit.coverage_area_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.coverageAreaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="D" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="division_id"
                                                                        v-model="form_edit.division_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.divisionList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="district_id"
                                                                        v-model="form_edit.district_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= districtList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="thana_id">Thana <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="thana_id"
                                                                        v-model="form_edit.thana_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= thanaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Zone" vid="zone_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="zone_id">Zone <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="zone_id"
                                                                        v-model="form_edit.zone_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= zoneList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="area_id"
                                                                        v-model="form_edit.area_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= areaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Category" vid="category_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="category_id">Category <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="category_id"
                                                                        v-model="form_edit.category_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= customState.categoryList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <ValidationProvider name="Instruction (Note)" vid="instruction" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="instruction">Instruction (Note)</label>
                                                                    <input type="text" id="instruction" v-model="form_edit.instruction" class="form-control" placeholder="Instruction (Note)" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Reference ID" vid="ref_id" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="ref_id">Reference ID</label>
                                                                    <input type="text" id="ref_id" v-model="form_edit.ref_id" class="form-control" placeholder="Reference ID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Amount to Collected" vid="price" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="price">Amount to Collected <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="price" v-model="form_edit.price" class="form-control" placeholder="Amount to Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Collected" vid="collected" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="price">Collected <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="collected" v-model="form_edit.collected" class="form-control" placeholder="Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Exchange" vid="exchange" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="exchange">Exchange <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="exchange"
                                                                        v-model="form_edit.exchange"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= exchangeList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="rider_id">Rider </label>
                                                                    <v-select name="rider_id"
                                                                        v-model="form_edit.rider_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.riderList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Status" vid="status" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="name">Status</label>
                                                                    <select id="status" v-model="form_edit.status" @change="statusChange($event)"  class="form-control">                                            
                                                                        <option v-for="(status,index) in customState.userStatusList" :key="index" :value="status.id">{{ status.text }}</option>
                                                                    </select>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div> 
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isRescheduleDate">
                                                             <ValidationProvider name="Reschedule Date" vid="reschedule_date" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="reschedule_date">Reschedule Date <span class="text-danger" title="Required">*</span></label>        
                                                                    <input type="date" v-model="form_edit.reschedule_date" class="form-control" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                             </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="isReason">
                                                            <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="reason">Reason <span class="text-danger" title="Required">*</span></label>    
                                                                    <v-select name="reason_id"
                                                                        v-model="form_edit.reason_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.reasonList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isReasonText">
                                                            <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="reason">Reason Text <span class="text-danger" title="Required">*</span></label>        
                                                                    <input type="text" v-model="form_edit.reason" class="form-control"/>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <button type="button" class="btn btn-info btn-sm mr-2 mt-30" @click.prevent="updateForm">Submit</button>
                                                                <button type="reset" class="btn btn-danger btn-sm mt-30" @click="cancelModal">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </form>
                                            </ValidationObserver>
                                        </b-overlay>                                     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading2" style="padding:0px">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                            Others
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse2" class="collapse show" aria-labelledby="heading2" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="name">Order Information</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tr>
                                                            <th>Order ID : {{ form_edit.tracking_id }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Reference ID : {{ form_edit.ref_id }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name : {{ form_edit.name }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Mobile : {{ form_edit.mobile }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Address : {{ form_edit.address }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Instruction : {{ form_edit.instruction }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Deadline : {{ form_edit.date }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Amount : {{ form_edit.price }}</th>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="name">Payment Details</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tr>
                                                            <th>Amount</th>
                                                            <td>{{ form_edit.price }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Collected </th>
                                                            <td>{{ form_edit.collected }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Service Charge</th>
                                                            <td>{{ form_edit.service_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Return Charge</th>
                                                            <td>{{ form_edit.return_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Area Charge</th>
                                                            <td>{{ form_edit.area_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Weight Charge</th>
                                                            <td>{{ form_edit.weight_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>COD Charge</th>
                                                            <td>{{ form_edit.status > 5 ? form_edit.cod : 0 }}</td>
                                                        </tr>
                                                    </table>
                                                    <button class="btn btn-success btn-sm mr-2" @click="printPos">
                                                        POS <i class="fa fa-print"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name">Order Activity</label>
                                                    <table class="table table-bordered table-sm" style="width:100%">
                                                        <tr>
                                                            <th style="width:20%">Action By</th>
                                                            <th style="width:55%">Action</th>
                                                            <th style="width:25%">Date & Time</th>
                                                        </tr>
                                                        <tr v-for="(log, index) in form_edit.logs" :key="index">
                                                            <td>{{ log.operation_by }}</td>
                                                            <td>{{ log.operation }}</td>
                                                            <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>

        <div v-if="deleteModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyOrder">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>

        <div v-if="actionPanelModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Rapid Action on Order</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">                        
                            <input type="text" class="form-control" v-model="barcode.sku" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"/>
                        </div>
                        <div class="form-group"> 
                            <span v-for="(order, index) in orderList" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                {{ order }}<i class="ml-1 fas fa-times" @click="removeRow(index)"></i> 
                            </span>
                        </div>
                        <div class="form-group">   
                            <h6> 
                                Total : <b>{{ orderList.length != 0 ? orderList.length : this.totalSelected }}</b>                            
                                <button class="ml-1 btn btn-default btn-sm" @click="scanDone" v-if="isScan">Done <i class="fa fa-check"></i></button>
                                <slot v-if="loading">
                                    <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                </slot>
                                <slot v-else>
                                    <button class="ml-1 btn btn-warning btn-sm" @click="printPos">POS <i class="fa fa-print"></i></button>
                                    <button class="ml-1 btn btn-success btn-sm" @click="actionPanelOrderToPdf">PDF <i class="fa fa-download"></i></button>
                                    <button class="ml-1 btn btn-success btn-sm">
                                        <export-excel
                                            :data="dataCustomize"
                                            :fields= "json_fields"
                                            >
                                            Excel <i class="fa fa-download"></i>
                                        </export-excel>
                                    </button>
                                    <!-- <button class="ml-1 btn btn-info btn-sm" @click="bulkAction">Submit</button> -->
                                    <!-- <button v-if="authUser.role_id <= 2" class="ml-1 btn btn-danger btn-sm" @click="bulkDelete">Delete</button> -->
                                </slot>
                            </h6>                        
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="name">Rider Assign</label>
                                    <v-select name="reason_id"
                                        v-model="bulk.rider_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.riderList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                    <span class="text-danger" v-if="errors.merchant_id">{{ errors.merchant_id[0] }}</span>
                                </div>   
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="status">Status </label>   
                                    <v-select name="status"
                                        v-model="bulk.status"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= customState.userStatusList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                    <input type="hidden" v-model="status.key">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="status">Merchant</label>   
                                    <v-select name="status"
                                        v-model="bulk.merchant_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.merchantList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="status">Hub</label>   
                                    <v-select name="status"
                                        v-model="bulk.hub_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= commonObj.hubList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="status">Payment Status </label>                        
                                    <select class="form-control" v-model="bulk.payment_status">
                                        <option :value="1">Due</option>
                                        <option :value="3">Paid</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /.content -->
        <div id="printSection" style="display:none">
            <div id="invoicePOS">
                <table class="table" v-for="(printorder,index) in printorders" :key="index" style="margin-left:-10px; width:160px; height:250px;font-family: sans-serif;font-size:10px;page-break-after:always;">
                    <tr>
                        <td colspan="2" style="text-align: center;">
                            <div class="login-logo"><img style="width: 50px;height:30px" :src="'./images/logo.png'"/></div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center;"><b>Bahokcourier.com</b></td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center;"><b>Order Description</b></td>
                    </tr>
                    <tr>
                        <th style="float:left">Date: </th><td>{{ printorder.date }}</td>
                    </tr>
                    <tr>
                        <th style="float:left">Ref: </th><td>{{ printorder.ref_id }}</td>
                    </tr>
                    <tr>
                        <th style="float:left">Order ID: </th><td>{{ printorder.tracking_id }}</td>
                    </tr>
                    <tr>
                        <th style="float:left">Area: </th><td>{{ printorder.zone_name }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center; border-bottom: 1px dashed; border-top: 1px dashed; padding:3px 0px;">
                            <img :src="generateBarcode(printorder.id)" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><b>Customer Details:</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ printorder.name }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ printorder.mobile }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ printorder.address }}</td>
                    </tr>
                    <tr style="margin-top:5px;">
                        <td colspan="2" style="border-top:1px solid"><b>Delivery Instruction:</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ printorder.instruction }}</td>
                    </tr>
                    <tr style="margin-top:5px;">
                        <td colspan="2" style="border-top:1px solid"><b>Merchant Details:</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ printorder.merchant_name }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="border-bottom:1px solid">{{ printorder.merchant_mobile }}</td>
                    </tr>
                    <tr>
                        <th style="float:left">Total</th><td>{{ printorder.price }} BDT</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center; border-top:1px solid">This is an auto generated report of</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center;">Bahok.com</td>
                    </tr>
                </table>
            </div>
            <!--End Invoice-->
        </div>
    </div>
</template>
<script>
    import ExportPdf from './Order.js'
    import infineLoading from 'vue-infinite-loading'
    import config from '@/config'
    import JsBarcode from 'jsbarcode'    
    import moment from 'moment'    
    const excelColumn = {
        'Order ID': 'order_id',
        'SKU': 'order_sku',
        'Merchant': 'order_merchant_name',
        'Reference ID': 'order_ref_id',
        'Contact Name': 'order_contact_name',
        'Contact Number': 'order_contact_number',
        'Address': 'order_address',
        'Date': 'order_date',
        'Assign Date': 'order_assign_date',
        'Amount': 'order_amount',
        'Collected Amount': 'order_collected',
        'Rider': 'order_rider_name',
        'Status': 'order_status'
    }
    export default {
        name:'Order',
        components: {            
            infineLoading: infineLoading,
        },
        data(){
            return {
                isExcelDownload: false,
                summary: null,
                spinner: true,
                loader: true, 
                loading: false,
                isScan: false,
                isSelected: true,
                selected: [],
                allSelected: false,
                ordersData: [],
                zones:[],
                orders:[],
                totalOrder: 0,
                order_id: '',
                printorders:[],
                barcode:{
                    sku: ''
                },
                orderList: [],
                page: 1,
                search: {
                    isSearch    : 0,
                    contact     : '',
                    sku         : '',
                    ref_id: '',
                    merchant_id : '',
                    rider_id    : '',
                    area_id : '',
                    // zone_id        : 0,
                    payment_status : 4,
                    status         : '',
                    date_parameter_id  : 4,
                    date_type_id  : 2,
                    source_hub_id : '',
                    destination_hub_id : '',
                    start_date    : moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date      : moment().format('YYYY-MM-DD')
                },
                form_edit:'',
                errors:[],
                createModal: 0,
                issueModal: 0,
                issue: '',
                orderEditModal:false,
                deleteModal:false,
                actionPanelModal:false,
                zone_id:'',
                rider_id: 0,
                bulk:{
                    order_ids: [],
                    status: '',
                    merchant_id: '',
                    rider_id: '',
                    hub_id: '',
                    payment_status: 1
                },
                status:{
                    key: '',
                    sku: '',
                    status: '',
                    collected: 0,
                    reason:' ',
                    reason_id: 0,
                },
                isRescheduleDate: false,
                isReason: false,
                isReasonText: false,
                areaList: [],
                totalSelected: 0,
                takeAction: true,
                key:''
            }
        },
        watch: {
            stateReload: function (newVal) {
                if (newVal) {
                    this.searchOrder()
                }
            },
            'form_edit.division_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.districtList = this.getDistrictList(newVal)
                }
            },
            'form_edit.district_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.thanaList = this.getThanaList(newVal)
                }
            },
            'form_edit.thana_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.zoneList = this.getZoneList(newVal)
                    this.areaList = this.getAreaList(newVal)
                }
            },
            'form_edit.weight': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.form_edit.weight_charge = this.getWeightCharge(newVal)
                }
            },
            '$route.query.id': function (newVal, oldVal) {
                if(newVal != oldVal) {
                    Object.assign(this.search, { sku: this.$route.query.id })
                    this.loadSummary()()
                    this.loadData()
                }
            }
        },
        created () {
            this.loadSummary();
            this.loadData();
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            customState () {
                return this.$store.state
            },
            authUser () {
                return this.$store.state.authUser
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                if (this.isExcelDownload) {
                    if (this.printorders.length > 0) {
                        this.printorders.map(item => {
                            item.order_id = item.id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant_name,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_name,
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.printorders
                    } else if (this.orders.length > 0) {
                        this.orders.map(item => {
                            item.order_id = item.id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant_name,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_name,
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.orders
                    } else {
                        return []
                    }
                }
                return []
            }
        },
        methods:{
            dateTypeChange () {
                const dateTypeId = this.search.date_type_id
                if (dateTypeId == 1) {
                    this.search.start_date = this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 2) {
                    this.search.start_date = this.search.end_date = moment().subtract(1,'d').format('YYYY-MM-DD')
                } else if (dateTypeId == 3) {
                    this.search.start_date = moment().subtract(31,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 4) {
                    this.search.start_date = moment().subtract(180,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 5) {
                    this.search.start_date = moment().subtract(365,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                }
            },
            reload () {
                this.commonStatus = []
                this.search = Object.assign({}, {
                    isSearch    : 0,
                    contact     : '',
                    sku         : '',
                    ref_id: '',
                    merchant_id : '',
                    rider_id    : '',
                    // zone_id     : 0,
                    area_id     : '',
                    payment_status : 4,
                    status      : [],
                    date_parameter_id  : 4,
                    date_type_id  : 2,
                    source_hub_id  : '',
                    destination_hub_id  : this.authUser.role_id == 7 ? this.authUser.hub_id : '',
                    start_date : moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date : moment().format('YYYY-MM-DD')
                })
                this.orderList = this.ordersData = this.printorders = []
                this.totalSelected = 0
            },
            selectAll: function() {   
                this.takeAction = false                
                this.ordersData = [];
                this.orderList = [];
                if (!this.allSelected) {
                    for (var order in this.orders) {
                        this.ordersData.push(this.orders[order])
                        this.orderList.push(this.orders[order].id);
                    }
                    this.totalSelected = this.orderList.length
                } else {
                    this.totalSelected = 0
                }
            },
            singleSelect(order) {
                this.takeAction = false
                if (!this.orderList.includes(order.id)) {
                    this.orderList.push(order.id)
                } else {
                    this.orderList.splice(this.orderList.indexOf(order.id), 1)
                }     
                this.totalSelected = this.orderList.length          
                this.allSelected = false;                
            },
            generateBarcode (orderNo) {
                let canvas = document.createElement('canvas');
                JsBarcode(canvas, orderNo, { 
                    format: 'CODE39',
                    displayValue: false,
                    height: 15,
                    width: 1,
                    padding: 0,
                    margin: 0
                });
                return canvas.toDataURL('image/jpeg');
            },
            actionPanelOrderToPdf() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.exportPdfDetails(base64Logo, this.printorders)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            getStatus (status) {
                const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
                return tmpStatus.text
            },
            getPaymentStatus (paymentStatus) {
                if (paymentStatus === 1) {
                    return 'Due'
                } else if (paymentStatus === 2) {
                    return 'Processing'
                } else if (paymentStatus === 3) {
                    return 'Paid'
                }
            },
            searchOrder() {
                this.search.isSearch = 1
                this.totalOrder = 0
                this.orders = []
                this.page = 1
                this.loadSummary()
                this.loadData()
            },
            async loadSummary() {
                this.loading = true
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { destination_hub_id: this.authUser.hub_id }) : this.search 
                const response = await config.getData('trash-order/summary', params)
                this.loading = false
                if (response.success) {
                    this.summary = response.summary
                } else {
                    this.summary = null
                }
                // this.$store.dispatch('summarySearch', false)
            },
            loadData($state) {
                // this.$store.dispatch('summarySearch', true)
                this.loader = false
                let vm = this;
                const params = Object.assign({}, this.search, { page: this.page })
                config.getData('trash-order', params)
                .then(res => {
                    return res
                }).then(response => {
                    // this.summary = response.summary
                    this.totalOrder += response.data.data.length
                    response.data.data.map(item => {
                        const tmpItem = this.getRelationalData(item)
                        vm.orders.push(tmpItem)
                    })
                    if (this.totalOrder < response.data.total) {
                        this.spinner = true
                        $state.loaded()
                    } else {
                        this.spinner = false
                    }
                });
                
                this.page = this.page + 1;
            },
            getRelationalData (item) {
                const startDate = moment(item.date).format("YYYY-MM-DD")
                const todayDate = moment().startOf('day')
                const newAge = moment.duration(todayDate.diff(startDate)).asDays();
                return Object.assign(item, {
                    age: newAge
                })
            },
            changeWeight(weightChargeId){
                const tmpCharge = this.commonObj.weightChargeList.find(charge => charge.id == weightChargeId)
                this.form_edit.weight_charge = tmpCharge.charge
            },
            editModal (index, order){
                document.body.classList.add("modal-open");
                this.isRescheduleDate = order.reschedule_date != null ? true : false
                this.isReasonText = order.reason != null ? true : false
                this.form_edit =  JSON.parse(JSON.stringify(order))
                this.key = index
                this.orderEditModal = true  
            },
            async updateForm () {
                this.loading = true
                // this.$store.dispatch('stateReload', true)
                const response = await config.postData('/order/update', this.form_edit)
                this.loading = false
                if (response.success) { 
                    this.searchOrder()                   
                    this.isReason = this.isRescheduleDate = this.isReasonText = false
                    this.orderEditModal = false
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.editForm.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
                // this.$store.dispatch('stateReload', false)
            },
            cancelModal(){                
                this.orderEditModal = this.deleteModal = this.actionPanelModal = false
                this.isReason = this.isReasonText = this.isRescheduleDate = this.isScan = this.isExcelDownload = false
                this.allSelected = false
                this.barcode.sku = ''
                this.totalSelected = 0
                this.printorders = []
                this.ordersData = []
                this.orderList = []   
                this.bulk.rider_id = this.bulk.status = this.bulk.merchant_id = this.bulk.hub_id = ''
                this.bulk.payment_status = 1
            },
            deleteOrder(key, order_id){
                this.order_id = order_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroyOrder(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.deleteData(`/trash-order/destroy/${this.order_id}`)
                this.loader = false
                if (response.status == 200) {
                    this.deleteModal = false
                    this.$toast.success('Data deleted successfully') 
                } else {
                    this.$toast.error('Data not deleted') 
                }
                this.$store.dispatch('stateReload', false)
            },
            async bulkAction(){ 
                this.loading = true 
                if (this.orderList.length > 0) {
                    const orderIdList = this.orderList.filter(val => (val !== undefined) && (val !== null));                     
                    this.bulk.order_ids = []         
                    this.bulk.order_ids = orderIdList
                }
                const skuList = this.barcode.sku.split(" ")
                if (!this.isScan && skuList.length > 0) {
                    skuList.forEach((value) => {
                        const orderId = value.split("-")[2]
                        this.bulk.order_ids.push(orderId)
                    })
                }
                const response = await config.postData('/order/bulk-action', this.bulk)
                this.loading = false 
                if (response.status == 201){   
                    this.orders = response.data
                    this.bulk.status = this.bulk.merchant_id = this.bulk.rider_id = this.bulk.hub_id = ''
                    this.bulk.payment_status = 1
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
            async bulkDelete(){  
                if (this.orderList.length > 0) {
                    const orderIdList = this.orderList.filter(val => (val !== undefined) && (val !== null));                     
                    this.bulk.order_ids = []         
                    this.bulk.order_ids = orderIdList
                }
                this.loader = true
                const response = await config.postData('/trash-order/bulk-delete', this.bulk)
                this.loader = false
                if (response.status == 200){   
                    this.orders = [] 
                    this.bulk.status = this.bulk.merchant_id = this.bulk.rider_id = this.bulk.hub_id = ''
                    this.bulk.payment_status = 1
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
            showActionPanelModal () {
                this.isScan = true
                this.actionPanelModal = true;
            },
            keepOrder() { 
                let skuList = this.barcode.sku.split(" ")
                if(skuList.length > 1) {
                    skuList.map(item => {
                        this.orderList.push(item);
                    })
                } else {
                    this.orderList.push(this.barcode.sku); 
                }
                this.isScan = true  
                this.barcode.sku = ''
            },
            async scanDone () {
                this.loading = true
                const response = await config.postData('/order/scaned-order', { 'order_ids': this.orderList })                        
                this.loading = false                                          
                this.isScan = false                                          
                if (response.status === 200) {                                           
                    this.printorders = response.data;
                    this.isExcelDownload = true
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Data not Found !!',
                        color: '#dc3545'
                    })
                }
            },
            removeRow(index){
                this.isScan = true
                this.orderList.splice(index, 1)
            },
            printPos() {
                this.$htmlToPaper("printSection");
            },
            statusChange (event) {
                this.isRescheduleDate = this.isReason = this.isReasonText = false
                var status = event.target.value
                if (status == 18) {
                    this.isRescheduleDate = true;
                    this.isReasonText = true
                } else if (status >= 15 && status <= 19) {
                    this.isReasonText = true
                } else if (status == 20) {
                    this.isReason = true
                }
            },
            getDateParameter (dateParameterId) {
                const dateParameter = this.customState.dateParameterList.find(param => param.id == dateParameterId)
                return dateParameter.text
            },
            getRiderName (riderId) {
                const rider = this.commonObj.riderList.find(rider => rider.id == riderId)
                return rider.text
            },
            getMerchantName (merchantId) {
                const merchant = this.commonObj.merchantList.find(merchant => merchant.id == merchantId)
                return merchant.text
            },
            getZones () {
                const areaId = this.form_edit.area_id
                const tmpZones = this.$store.state.commonObj.zoneList.find(tmp => tmp.area_id == areaId)
                this.zones = tmpZones
            },
            getWeightCharge (weight) {
                const tmpCharge = this.commonObj.weightChargeList.find(el => el.id == weight)
                return typeof tmpCharge != 'undefined' ? tmpCharge.charge : 0
            },
            getCategoryName (categoryId) {
                const category = this.customState.categoryList.find(cat => cat.id === categoryId)
                return  category.text
            },
            getDistrictList (divisionId) {
                return this.commonObj.districtList.filter(el => el.division_id == divisionId)
            },
            getThanaList (districtId) {
                return this.commonObj.thanaList.filter(el => el.district_id == districtId)
            },
            getZoneList (thanaId) {
                return this.commonObj.zoneList.filter(el => el.thana_id == thanaId)
            },
            getAreaList (thanaId) {
                return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
            }
        }
    }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }
</style>